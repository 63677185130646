import { Interval } from "luxon"
import { Card, CardBody, CardHeader } from "reactstrap"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"

import HtmlContent from "../HtmlContent"
import { EventItem } from "./RSSFeedToEventsView"


interface IProps {
  event: EventItem
  /**
   * if the links to the event should not be opened in the same browser window/tab but in another,
   * provide a target, e.g. target="_new"
   */
  targetWindow?: string
}

const usedNamespaces: NamespaceShortcut[] = ["events"]

/**
 * This component provides a card to show an event
 *
 * @todo css styles, rename style class "project-information" to more common name
 */
const EventCard: React.FC<IProps> = (props: IProps) => {
  const { event, targetWindow } = props
  const t = useDynamicTranslation()

  return <Card className="event-card">
    <CardHeader className="event-card__header">
      <div className="title-section">
        <span className="project-information">
          {Interval.fromISO(event.eventDate.startDate[0] + "/" + event.eventDate.endDate?.[0])
            .toLocaleString({ month: '2-digit', year: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
        </span>
        <h5 className="event-card__title">{event.title}</h5></div>
    </CardHeader>
    <CardBody className="event-card__body">
      <div className="event-card__location">
        {/* use HtmlContent, because data contains special characters */}
        <HtmlContent content={event.description} />
      </div>
      {/* because it is an external link we do not use the next/link component */}
      <a href={event.link} target={targetWindow} className="btn primary-btn event-card__button">{t("events", "externalLinkToEvent")}</a>
    </CardBody>

  </Card>
}

export default withDynamicNamespaces<IProps>(EventCard, usedNamespaces)
