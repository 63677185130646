import axios from "axios"
import { useEffect, useState } from "react"
import { Row } from "reactstrap"
import Parser from 'rss-parser'

import { logUnhandledUnexpectedError, platformIsInDevEnvironment } from "@services/util"

import EventCard from "./EventCard"


interface IProps {
  /**
   * URL of the feed file
   */
  url: string
  /**
   * maximal number of events to be shown
   */
  maxNumberOfEvents?: number
  /**
   * how many hours in the past may an event have ended to be shown
   * default: 24
   * means: only those events are visible, that ended within the last 24 hours
   */
  hideOldEventsAfterHours?: number
  /** alternate classname for the event cards collection container, default:  */
  className?: string
  /** it may be useful to postprocess the loaded json, e.g. to transform single item elements */
  postprocessLoadedJson?: (feed: EventFeed & Parser.Output<EventItem>) => EventFeed & Parser.Output<EventItem>
  /**
   * if the links to the events should not be opened in the same browser window/tab but in another,
   * provide a target, e.g. target="_new"
   */
  targetWindow?: string
}

/**
 * type/structure of the rss feed
 *
 * new/changed/removed custom keys must also be added/updated/removed to the costumFields of the new Parser() call (see below)
 */
export interface EventFeed {
  description: string
  lastBuildDate: string | Date
  link: string
  pubDate: string | Date
  title: string
}

/**
 * type/structure of an item, included as <item></item> of the feed and as items[] in the parsed JSON
 *
 * in fact the JSON items entries are extended by more fields by Parser.Item, even if they are not part of the real RSS
 *
 * new/changed/removed custom keys must also be added/updated/removed to the new Parser() customfields (see below)
 */
export interface EventItem extends Parser.Item {
  content: string
  description: string
  eventDate: {
    // @todo: why is startDate/endDate given as array? the raw data does not look like an array
    startDate: string[]
    endDate: string[]
  }
  eventLocation: string
  link: string
  title: string
}

/**
 * custom fields are those fields that are not already known to and parsed by the Parser because they
 * are not part of Parser.Item and Parser.Output
 *
 * to parse additional custom keys they must be added manually here
 * to access them in the resulting JSON they must be also added to the pre-defined interfaces (see above)
 *
 * NOTE: there will errors if customFields to not match the interface keys
 */
const parser: Parser<EventFeed, EventItem> = new Parser({
  customFields: {
    feed: ['description', 'lastBuildDate', 'link', 'pubDate', 'title'],
    item: ['content', 'description', 'eventDate', 'eventLocation']
  }
})


/**
 * This component provides a RSS Feed fetched from a URL and handles it as dates/events
 *
 * NOTE: because of CORS problems the RSS feed file must be put onto the client server! @see /docs/learnings.md#cors
 * therefor it is not possible to use this component as
 * <RSSFeedToEventsView url="https://www.b-tu.de/ikmz/projekte/ideenlab/aktuelles/veranstaltungen?type=9818" />
 * but as
 * <RSSFeedToEventsView url={BASE_URL + "/assets/rss/events.xml"} />
 *
 * NOTE: the structure of the EventFeed and EventItem (+ Parser custom fields) is valid for Cottbus customer
 * but may need to be adapted to work for other RSS feeds
 *
 * @todo build api endpoint to allow fetching the RSS feed URL via the backend as "proxy" to avoid cors problems
 * @todo nice to have: how to avoid to define the interface EventFeed/EventItem as well as the customField-list when instantiating the parser? #FCP-1089
 * @todo css styles for this component + EventCard
 */
const RSSFeedToEventsView: React.FC<IProps> = (props: IProps) => {
  const {
    maxNumberOfEvents,
    hideOldEventsAfterHours = 24,
    url,
    className = "event-card-layout",
    postprocessLoadedJson,
    targetWindow
  } = props

  const [feedRawData, setFeedRawData] = useState<string>()
  const [feedAsJson, setFeedAsJson] = useState<Parser.Output<EventItem> & EventFeed>()


  // when running in development environment a proxy is needed to avoid CORS problems for external URLs
  // but the proxy must be avoided if localhost is called, because the proxy does not have access to localhost
  // 10 cors proxies: https://nordicapis.com/10-free-to-use-cors-proxies/
  //
  // this MUST NOT be used in production environment because of data protection issues
  // in test mode the data fetch must be mocked
  const proxyUrl = platformIsInDevEnvironment() && !url?.includes('://localhost')
    ? `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`
    : url

  /**
   * fetches a given URL and puts the result into the local state
   * expects a XML RSS feed file
   */
  useEffect(() => {
    axios.get(proxyUrl, {})
      .then((response) => {
        setFeedRawData(response.data as string)
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        logUnhandledUnexpectedError("Error on loading url " + proxyUrl + " in RSS events component: " + err, "fetching-url")
      })
  }, [])

  // parses the loaded XML when the URL fetch was finished
  useEffect(() => {
    if (!feedRawData) {
      return
    }

    (async () => {
      // it could be possible to directly parse the URL by the Parser:
      // const feed = await parser.parseURL(proxyUrl)
      // but for debugging issues we fetch the raw data seperately
      // and handle this data here for transforming to JSON
      let feed = await parser.parseString(feedRawData)
      // postprocess the loaded data, e.g. for customizations for customers
      if (postprocessLoadedJson) {
        feed = postprocessLoadedJson(feed)
      }
      setFeedAsJson(feed)
    })()
      // eslint-disable-next-line no-console
      .catch((err) => console.log("Error when parsing the raw data:", err))
  }, [feedRawData])


  return feedAsJson?.items
    ? <Row className={className}>
      {feedAsJson.items
        // filter out all outdated events
        .filter(event => event.eventDate.startDate && (Date.now() < Date.parse(event.eventDate.endDate[0] || event.eventDate.startDate[0]) + hideOldEventsAfterHours * 60 * 60 * 1000))
        // sort the events: earliest first
        .sort((i1, i2) => i1.eventDate.startDate?.[0] < i2.eventDate.startDate?.[0] ? -1 : 1)
        // cut to the number of wanted events
        .slice(0, maxNumberOfEvents)
        .map((item, index: number) => <EventCard key={index} event={item} targetWindow={targetWindow} />)
      }
    </Row>
    : null
}

export default RSSFeedToEventsView
